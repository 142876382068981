import moment from 'moment';

// Custom console and performance measurement tools
export var gs = {
  _log: '',
  _lastTime: moment(),

  logItem: function(item) {
    this._log += item + '\n';
    if (this._log.length > 10000) {
      this._log = this._log.substring(this._log.length - 10000, this._log.length);
    }
  },

  log: function (item) {
    if (arguments.length) {
      var item = arguments[0];
      for (var arg = 1; arg < arguments.length; ++arg) {
        item += ' --- ' + arguments[arg];
      }
      this.logItem(item);
      console.log(...arguments);
    }
  },

  warn: function () {
    if (arguments.length) {
      var item = "WARN: " + arguments[0];
      for (var arg = 1; arg < arguments.length; ++arg) {
        item += ' --- ' + arguments[arg];
      }
      this.logItem(item);
      console.warn(...arguments);
    }
  },

  error: function () {
    if (arguments.length) {
      var item = "ERROR: " + arguments[0];
      for (var arg = 1; arg < arguments.length; ++arg) {
        item += ' --- ' + arguments[arg];
      }
      this.logItem(item);
      console.error(...arguments);
    }
  },
  
  alert: function (force = false) {
    this.warn("in GS alert - init");
    try {
      // if (isUserAnAppArchitect()) {
      //   force = true;
      // }

      // if (force) {
      //   appNavigatorRef.dispatch(NavigationActions.navigate({ routeName: "LogScreen", }));
      // }
    }
    catch (error) {
      this.warn("ERROR: in gs.alert - appNavigatorRef - error=" + error);
    }
  },

  
  time: function (item = '') {
    let newTime = moment();
    let dt = newTime.diff(this._lastTime);
    this._lastTime = newTime;
    let entry = dt + 'ms: ' + item;
    this.log(entry);
  },
  // warn: function (item = '') {
  //   let newTime = moment().format("HH:mm:ss.SSS");
  //   let entry = newTime + ': ' + item;
  //   this.warn("GS warn: " + entry);
  // },

  getLog: function() {
    return this._log;
  }
}


export function convertMomentToMatchdayStr(dtm) {
  // gs.warn("in convertMomentToMatchdayStr - input =", dtm);

  let theDate = null;
  if (!dtm){
    theDate = moment().tz("America/New_York");
  }
  else {
    theDate = moment(dtm).tz("America/New_York");
  }

  let myHourNY = parseInt(theDate.format('H'), 10);
  
  //matchday is 4:00am - 3:59am next day
  if (myHourNY < 4) {
    // gs.log("MATCHDAY: still in yesterday's matchday");
    theDate = theDate.subtract(1, 'day');
  }

  let matchdayStr = theDate.format('YYYY-MM-DD');

  // gs.warn("in convertMomentToMatchdayStr - output =", matchdayStr);

  return matchdayStr;
}

gs.log("@@@@@@@ helpers.js LAST");

