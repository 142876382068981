import React, { Component } from 'react';
import {
  View,
  TouchableWithoutFeedback,
  Animated,
  Easing,
  Image,
  Text,
  Dimensions,
  StyleSheet,
} from 'react-native';
import { gs } from './helpers';


class InfoOverlay extends Component {
  constructor(props) {
    super(props);

    this.state = {

    };
    this.timer = undefined;
    this.showDuration = 300;
    this.timerStart = 150;

    this.enablePop = false;
  }

  
  hide = () => {
    if (this.enablePop) {
      Animated.timing(this.timer, {
        toValue: this.timerStart,
        duration: this.showDuration,
        easing: Easing.inOut(Easing.quad),
        useNativeDriver: false
      }).start(() => {
        this.props.hideFn();
      });
    }
  }

  
  render() {
    gs.log("in InfoOverlay --- render! - version: enhanced logging");
    const {height, width} = Dimensions.get('window');

    if (this.timer === undefined) {
      this.timer = new Animated.Value(this.timerStart);
      // this.timer.setValue(100);
      Animated.timing(this.timer, {
        toValue: 0,
        duration: this.showDuration,
        easing: Easing.inOut(Easing.quad),
        useNativeDriver: false
      }).start(() => {

        setTimeout(() => {
          this.enablePop = true;
        }, 200);
      });
    }

    return (
      <View style={{
        position: 'absolute',
        width: width,
        height: height,
        justifyContent: 'center', alignItems: 'stretch',
      }}>
        {/* Full screen opaque mask, tap to exit */}
        <TouchableWithoutFeedback
          onPress={this.hide}
        >
          <Animated.View style={{
            flex: 1,
            margin: 20,
            padding: 20,
            backgroundColor: "#000000",
            borderRadius: 10,
            transform:
              [{
                scale: this.timer.interpolate({
                  inputRange: [0, this.timerStart],
                  outputRange: [1, 0.1]
                }),
              }],
          }}>

            <Image
              style={{
                width: 50,
                height: 50,
                tintColor: 'yellow',
                marginBottom: 30,
              }}
              source={require('./assets/info_icon_4x.png')}
            />

            {/* <Text style={styles.text}>OS: {Platform.OS}</Text> */}
            <Text style={styles.text}>navigator.userAgent: {navigator.userAgent}</Text>
            <Text style={styles.text}>is fullscreen (from window obj): {window.matchMedia('(display-mode: fullscreen)').matches ? 'Yes' : 'No'}</Text>
            <Text style={styles.text}>in browser (from window obj): {window.matchMedia('(display-mode: browser)').matches ? 'Yes' : 'No'}</Text>
            <Text style={styles.text}>navigator.standalone (iOS only): {navigator.standalone !== undefined ? (navigator.standalone ? 'Yes' : 'No') : 'N/A'}</Text>
            <Text style={styles.text}>App just installed: {this.props.appJustInstalled}</Text>
            <Text style={styles.text}>App is installed: {this.props.appIsInstalled}</Text>
            <Text style={styles.text}>navigator.userActivation.isActive: {navigator.userActivation ? (navigator.userActivation.isActive ? 'Yes' : 'No') : 'N/A'}</Text>
            <Text style={styles.text}>navigator.userActivation.hasBeenActive: {navigator.userActivation ? (navigator.userActivation.hasBeenActive ? 'Yes' : 'No') : 'N/A'}</Text>
            <Text style={styles.text}>document.referrer: {document.referrer}</Text>
            <Text style={styles.text}>Dimensions: {width} x {height}</Text>

          </Animated.View>
        </TouchableWithoutFeedback>

      </View>
    );
  }
}


export default InfoOverlay;


const styles = StyleSheet.create({
  text: {
    color: '#FFFFFF',
    lineHeight: 20,
    marginBottom: 20,
  },
});