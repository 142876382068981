import React, { Component } from 'react';
import {
  View,
  TouchableWithoutFeedback,
  Animated,
  Easing,
  Text,
  Dimensions,
  StyleSheet,
  ScrollView,
} from 'react-native';
import { gs } from './helpers';


export default class GSLog extends Component {
  constructor(props) {
    super(props);

    this.state = {

    };
    this.timer = undefined;
    this.showDuration = 300;
    this.timerStart = 150;

    this.enablePop = false;

    this.scrollRef = React.createRef();
  }

  
  hide = () => {
    if (this.enablePop) {
      Animated.timing(this.timer, {
        toValue: this.timerStart,
        duration: this.showDuration,
        easing: Easing.inOut(Easing.quad),
        useNativeDriver: false
      }).start(() => {
        this.props.hideFn();
      });
    }
  }

  
  render() {
    gs.log("in GSLog --- render!");
    const {height, width} = Dimensions.get('window');

    let gslog = gs.getLog().split(`\n`);
    
    if (this.timer === undefined) {
      this.timer = new Animated.Value(this.timerStart);
      // this.timer.setValue(100);
      Animated.timing(this.timer, {
        toValue: 0,
        duration: this.showDuration,
        easing: Easing.inOut(Easing.quad),
        useNativeDriver: false
      }).start(() => {

        try {
          this.scrollRef.current.scrollToEnd(); // {animated: false});
        }
        catch (e) { }

        setTimeout(() => {
          this.enablePop = true;
        }, 200);
      });
    }

    return (
      <View style={{
        position: 'absolute',
        width: width,
        height: height,
        justifyContent: 'center', alignItems: 'stretch',
      }}>
        {/* Full screen opaque mask, tap to exit */}
        <TouchableWithoutFeedback
          onPress={this.hide}
        >
          <Animated.View style={{
            flex: 1,
            margin: 20,
            padding: 20,
            backgroundColor: "#000000",
            borderRadius: 10,
            transform:
              [{
                scale: this.timer.interpolate({
                  inputRange: [0, this.timerStart],
                  outputRange: [1, 0.1]
                }),
              }],
          }}>
            <ScrollView
              ref={this.scrollRef}
            >

              <View>
                {gslog.map((item, index) => (
                  <Text
                    key={index}
                    style={styles.text}
                  >
                    {item}
                  </Text>
                ))}
              </View>

            </ScrollView>
          </Animated.View>
        </TouchableWithoutFeedback>
      </View>

    );
  }
}


const styles = StyleSheet.create({
  text: {
    color: '#FFFFFF',
    lineHeight: 20,
    marginBottom: 5,
  },
});
