import React from 'react';
import {
  StyleSheet,
  useWindowDimensions,
  Platform,
  View,
} from 'react-native';
import AppWrapper from './AppWrapper';

import * as serviceWorkerRegistration from "./src/serviceWorkerRegistration";

// function getPWADisplayMode() {
//   const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
//   if (document.referrer.startsWith('android-app://')) {
//     return 'twa';
//   } else if (navigator.standalone || isStandalone) {
//     return 'standalone';
//   }
//   return 'browser';
// }


// window.matchMedia('(display-mode: standalone)').addEventListener('change', (evt) => {
//   let displayMode = 'browser';
//   if (evt.matches) {
//     displayMode = 'standalone';
//   }
//   // Log display mode change to analytics
//   console.log('DISPLAY_MODE_CHANGED', displayMode);
// });


export default function App() {
  const {height} = useWindowDimensions();

  return (
    <View style={[styles.container, { height }, StyleSheet.absoluteFill]}>
      <AppWrapper />
    </View>
  );
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#202020',
    alignItems: 'center',
    justifyContent: 'center',
  }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();