import React, { Component } from "react";
import {
  StyleSheet,
  Text,
  View,
  Pressable,
  AppState,
} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import InfoOverlay from './InfoOverlay';


import moment from 'moment';
import GSLog from "./GSLog";
import { gs } from "./helpers";
// import 'moment-timezone';
// import { logAppOpen, logEvent } from "./analytics";
// import { backupAsyncStorage, restoreAsyncStorage } from "./firestore";


export var curAppState = AppState.currentState;
export var nowForegrounding = false;
export function setNowForegrounding(value) {
  nowForegrounding = value;
}

var lastBackgroundedEntry = null;


// var today = convertMomentToMatchdayStr();


async function _handleAppStateChange(nextAppState) {
  gs.log('AppStateChange: current: ' + curAppState + ' --> next: ' + nextAppState);

  const currentAppState = curAppState;
  curAppState = nextAppState;
  let mustPrepBackgounding = false;

  //foregrounding
  if (currentAppState.match(/unknown|inactive|background/) && nextAppState === 'active') {
    gs.time('AppStateChange: App has come to the foreground!');

    setNowForegrounding(true);

    // today = convertMomentToMatchdayStr(); // refresh

    //foregrounding workflow

    if (lastBackgroundedEntry) {
      let tempMoment600 = lastBackgroundedEntry.clone();
      let tempMoment60 = lastBackgroundedEntry.clone();

      if (tempMoment600.add(10 * 60, 'second').isBefore()) { //init app (return after 10 minutes or more)
        gs.log("AppStateChange: foregrounded - after 10 minutes - Last run=" + lastBackgroundedEntry);
        // lastBackgroundedEntry = null;

        setNowForegrounding(false); // deep linking is handled during app init

        gs.log("()()()()()() reload app after long backgrounding");
        // appReload();
        return;
      }
      else if (tempMoment60.add(60, 'second').isBefore()) {
        gs.log("AppStateChange: foregrounded - after 60 seconds and within 10 minutes - Last run=" + lastBackgroundedEntry);

        // if (loggedInUser.bareFruid) { //ONLY for logged-in users
          // await restoreAsyncStorage();
        // }

        // serveMainInterval(); //no need to await here...
      }
      else {
        // returned in less than 60 seconds
        gs.log("AppStateChange: foregrounded - WITHIN 60 seconds - Last run=" + lastBackgroundedEntry);
      }

      // gs.log('AppStateChange: clear lastBackgroundedEntry');
      // lastBackgroundedEntry = null; // clear previous entry at foregrounding
    }
    else { // extreme failsafe
      gs.log('AppStateChange: foregrounding BUT WITH NO lastBackgroundedEntry');
      setNowForegrounding(false); // deep linking is handled during app init

      gs.log("()()()()()() reload app in NO lastBackgroundedEntry");
      // appReload();
      return;
    }

    gs.log("AppStateChange: foregrounded - foregrounding process finished!");

    //spare some time - AppWrapper _handleOpenURL (FDL #3 or URL scheme) may fire
    setTimeout(() => {
      gs.log("AppStateChange: foregrounded - setNowForegrounding(false);");
      setNowForegrounding(false);
    }, 500);
  }

  // backgrounding state transitions
  else if (nextAppState === 'inactive' && currentAppState != nextAppState) {
    gs.log('AppStateChange: App has entered inactive state.');
    // logEvent('app', 'closed');
    mustPrepBackgounding = true;
  }

  else if (currentAppState === 'active' && nextAppState === 'background') {
    gs.log('AppStateChange: App has gone to the background...');
    // logEvent('app', 'backgrounded');
    mustPrepBackgounding = true;
  }

  else if (currentAppState === 'inactive' && nextAppState === 'background') {
    gs.log('AppStateChange: App has gone to the background when inactive...');
    // logEvent('app', 'backgrounded');
    // mustPrepBackgounding = true; // have already been executed when app went inactive
  }
  else {
    gs.log('AppStateChange: UNHANDLED STATE CHANGE ' + currentAppState + ' --> ' + nextAppState);
  }

  // perform backgrounding tasks
  if (mustPrepBackgounding) {
    gs.log('AppStateChange: mustPrepBackgounding (grab moment)');
    lastBackgroundedEntry = moment();

    // backupAsyncStorage(); // no await
  }
}


class AppWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      number: 0,
      savedNumber: 0,
      showInstallButton: false,
      showInfoOverlay: false,
      showGSLog: false,
      appJustInstalled: 'N/A',
      appIsInstalled: 'N/A',
    };

    this.myAppState = null;
  }

  
  async componentDidMount() {
    gs.log("in AppWrapper componentDidMount!");

    let res = await AsyncStorage.getItem('savedNumber');
    if (res !== null) {
      this.setState({ savedNumber: parseInt(res)});
    }

    window.addEventListener('beforeinstallprompt', (event) => {
      gs.log('👍 beforeinstallprompt ' + JSON.stringify(event));
      // Prevent the mini-infobar from appearing on mobile.
      event.preventDefault();

      // Stash the event so it can be triggered later.
      window.deferredPrompt = event;

      this.setState({ showInstallButton: true });
    });

    window.addEventListener('appinstalled', (event) => {
      gs.log('👍 appinstalled ' + JSON.stringify(event));
      // Clear the deferredPrompt so it can be garbage collected
      window.deferredPrompt = null;
      this.setState({ appJustInstalled: 'Yes'});
    });

    try {
      const relatedApps = await navigator.getInstalledRelatedApps();
      this.setState({ appIsInstalled: (relatedApps.length > 0) ? 'Yes' : 'No' });
    }
    catch {}

    try {
      this.myAppState.remove();
    } catch (e) { }

    this.myAppState = AppState.addEventListener('change', _handleAppStateChange);

    gs.log('user agent' + navigator.userAgent);
  }

  
  componentWillUnmount() {
    gs.log("in AppWrapper - view life cycle - componentWillUnmount!");

  }

  
  handlePress = async () => {
    await AsyncStorage.setItem('savedNumber', JSON.stringify(this.state.savedNumber + 1));
    this.setState({
      number: this.state.number + 1, 
      savedNumber: this.state.savedNumber + 1
    });
    gs.log(`just saved number ${this.state.number} ${this.state.savedNumber}`);
  }

  
  handleInstall = async() => {
    gs.log('👍 install-clicked');
    const promptEvent = window.deferredPrompt;
    if (!promptEvent) {
      // The deferred prompt isn't available.
      return;
    }
    // Show the install prompt.
    promptEvent.prompt();
    // Log the result
    const result = await promptEvent.userChoice;
    gs.log('👍 userChoice ' + JSON.stringify(result));
    // Reset the deferred prompt variable, since
    // prompt() can only be called once.
    window.deferredPrompt = null;
    // Hide the install button.
    this.setState({ showInstallButton : false });
  }

  
  render() {
    gs.log("in AppWrapper - render!");
    
    return (
      <View style={styles.container}>
        <Text style={styles.btnText}>LocalStorage counter: {this.state.savedNumber}</Text>
        <Text style={styles.btnText}>Local state counter: {this.state.number}</Text>
        <Pressable
          style={({ pressed }) => [
            {
              opacity: pressed ? 0.7 : 1,
            },
            styles.btn,
          ]}
          onPress={this.handlePress}>
          <Text style={styles.btnText}>Add 1 to both</Text>
        </Pressable>

        <Pressable
          style={styles.btn}
          onPress={() => {
            this.setState({ showInfoOverlay: true });
          }}>
          <Text style={styles.btnText}>Show info</Text>
        </Pressable>

        <Pressable
          style={styles.btn}
          onPress={() => {
            this.setState({ showGSLog: true });
          }}>
          <Text style={styles.btnText}>Show gs log</Text>
        </Pressable>

        <Text style={styles.text}>navigator.userActivation.isActive: {navigator.userActivation ? (navigator.userActivation.isActive ? 'Yes' : 'No') : 'N/A'}</Text>
        <Text style={styles.text}>navigator.userActivation.hasBeenActive: {navigator.userActivation ? (navigator.userActivation.hasBeenActive ? 'Yes' : 'No') : 'N/A'}</Text>

        {this.state.showInstallButton ?
          <Pressable
            style={styles.btn}
            onPress={this.handleInstall}>
            <Text style={styles.btnText}>Install App</Text>
          </Pressable> : null
        }

        {this.state.showInfoOverlay ?
          <InfoOverlay
            hideFn={() => {
              this.setState({ showInfoOverlay: false });
            }}
            appJustInstalled={this.state.appJustInstalled}
            appIsInstalled={this.state.appIsInstalled}
          /> : null
        }

        {this.state.showGSLog ?
          <GSLog
            hideFn={() => {
              this.setState({ showGSLog: false });
            }}
          /> : null
        }

      </View>
    )

  }
}

export default AppWrapper;



const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#202020',
    alignItems: 'center',
    justifyContent: 'center',
  },
  btn: {
    marginTop: 10,
    overflow: 'hidden',
    borderRadius: 10,
    width: 180,
    height: 45,
    justifyContent: 'center', alignItems: 'center',
    backgroundColor: '#55AD79',
    padding: 10,
    userSelect: 'none'
  },
  btnText: {
    color: '#FFFFFF',
    lineHeight: 20,
  },
  text: {
    color: '#FFFFFF',
    lineHeight: 20,
    marginTop: 20,
  },
});